<template>

  <v-row class="inner-content">
    <v-col>
      <NKWReviewTable />
    </v-col>
  </v-row>

</template>

<script>
export default {
  name: 'ReviewNKWs',

  components: {
    NKWReviewTable: () => import('@/components/tables/NKWReviewTable.vue')
  },
}
</script>
